export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    formatFallbackMessages: true,
    missingWarn: false,
    fallbackWarn: false,
    fallbackFormat: true,
    messages: {
        en: {},
        nl: {},
        de: {}
    }
}))
